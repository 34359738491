import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import _ from 'lodash';
import TotalUser from './TotalUser';
import TotalValueWidget from './TotalValueWidget';
import { getBalanceInfoAPI, getTotalUserInfoAPI, getTotalWalletInfoAPI, verifyAdminTokenAPI } from '../../../Library/apis';
import BalanceInfoWidget from './BalanceInfoWidget';
import UserContext from '../../../_helper/User';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { logOut } from '../../../Library/api_utils';

const Home = () => {
  const { userInfo } = useContext(UserContext);

  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isFinalcialLoading, setIsFinalcialLoading] = useState(false);

  const [totalValues, setTotalValues] = useState({
    totalSavingsWallet: 0,
    totalTermSavingsWallet: 0,
    totalInterestWallet: 0,
    totalReferralPaid: 0
  });

  const [totalUserInfo, setTotalUserInfo] = useState({
    totalAdminUser: 0,
    totalCustomerUser: 0
  });

  const [balanceInfo, setBalanceInfo] = useState({
    rate: 0.03,
    referralBonus: 500,
    min_refferal_bonus_fix_amount: 1000,
    term_savings_threshold: 1000,
    max_activity_count: 4,
    airtime_bonus_amount: 500,
    airtime_max_count: 4
  });

  const navigate = useNavigate();

  useEffect(() => {
    verifyAdminTokenAPI().then().catch((e) => {
      toast.warning("Your session is expired, please login again");
      logOut();
      navigate('/login');
    });
  }, []);

  useEffect(() => {
    if (userInfo.roles && userInfo.roles.includes('View Total Values')) {
      setIsBalanceLoading(true);
      getTotalWalletInfoAPI()
        .then(res => {
          setIsBalanceLoading(false);
          setTotalValues(res.total);
        })
        .catch(() => {
          setIsBalanceLoading(false);
        });

      setIsUserLoading(true)
      getTotalUserInfoAPI()
        .then(res => {
          setIsUserLoading(false)
          setTotalUserInfo(res.total);
        })
        .catch(() => {
          setIsUserLoading(false)
        });
    }
    if (userInfo.roles && (userInfo.roles.includes('View Balance Info') || userInfo.roles.includes('Set Balance Info'))) {
      setIsFinalcialLoading(true)
      getBalanceInfoAPI()
        .then(res => {
          setIsFinalcialLoading(false)
          setBalanceInfo(res.body);
        })
        .catch(() => {
          setIsFinalcialLoading(false)
        });
    }
  }, [userInfo]);

  // Memoize the Home component to prevent unnecessary re-renders
  const memoizedHome = useMemo(() => (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' />
      <Container fluid={true}>
        <Row className='widget-grid font-outfit'>
          {userInfo.roles && userInfo.roles.includes('View Total Values') && <TotalValueWidget data={totalValues} isLoading={isBalanceLoading} />}
          {userInfo.roles && userInfo.roles.includes('View Total Values') && <TotalUser data={totalUserInfo} isLoading={isUserLoading} />}
          {userInfo.roles && (userInfo.roles.includes('View Balance Info') || userInfo.roles.includes('Set Balance Info')) && <BalanceInfoWidget balanceInfo={balanceInfo} isLoading={isFinalcialLoading} />}
        </Row>
      </Container>
    </Fragment>
  ), [userInfo, totalValues, balanceInfo, totalUserInfo, isBalanceLoading, isFinalcialLoading, isUserLoading]);

  return memoizedHome;
};

export default Home;
