import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumbs, } from '../../../../AbstractElements';
import _ from 'lodash';
import StaffTable from './StaffTable';

const AllStaff = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Staff Management' />
            <Container fluid={true}>
                <StaffTable />
            </Container>
        </Fragment >
    );
};

export default AllStaff;
