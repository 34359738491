import React from "react";
import { Image } from "../../../AbstractElements";
import { IMAGES } from "../../../Library/theme";
import { useNavigate } from "react-router";

const MothriftLogo = ({ size = 200, white, style = {} }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "absolute",
        margin: "20px 0 0 40px",
        zIndex: 1,
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        navigate("/home");
      }}
    >
      <Image
        attrImage={{
          className: "p-0",
          src: white ? IMAGES.logo_white : IMAGES.logo_yellow,
          alt: "shield",
          style: { width: size },
        }}
      />
    </div>
  );
};

export default MothriftLogo;
