import React, { Fragment } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { Breadcrumbs, UL, LI, P, H6 } from '../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { getTimeDiff } from '../../../../Library/custom_utils';
import { useEffect } from 'react';
import { getAdminNotificationsAPI } from '../../../../Library/apis';
import { useState } from 'react';
import MothriftLoader from '../../Common/MothriftLoader';

const renderItem = (item, i) => {
  let status = 'success';
  if (item.event.includes('Create')) status = 'success'
  else if (item.event.includes('Update')) status = 'info'
  else if (item.event.includes('Disable')) status = 'warning'
  else if (item.event.includes('Delete')) status = 'secondary'
  else if (item.event.includes('Set')) status = 'primary'
  else if (item.title == '') status = 'primary'

  return (
    <UL attrUL={{ style: { marginLeft: '10px' } }}>
      <LI key={i} attrLI={{ className: 'd-flex' }}>
        <div className={`activity-dot-${status}`} />
        <div className='w-100 ms-3'>
          <P attrPara={{ className: 'd-flex justify-content-between mb-1' }}>
            <span className={`date-content light-background font-${status}`}>{getTimeDiff(item.createdAt)}</span>
          </P>
          <H6 attrH6={{ className: `mb-2` }}>
            {item.event}
            <span className='dot-notification' />
          </H6>
          <div className='d-flex align-items-center' >
            <P attrPara={{ className: `mb-0 me-2 f-w-700 text-${status}` }}>{item.adminUserInfo.userInfo.firstName + ' ' + item.adminUserInfo.userInfo.lastName}</P>
            <span className='f-light'>{item.title}</span>
          </div>
        </div>
      </LI>
    </UL>
  )
}

const customStyles = {
  header: {
    style: {
      borderBottom: '0px !important'
    },
  },
  rows: {
    style: {
      borderBottom: '0px !important'
    },
  },
};

const AdminNotifications = () => {
  const [adminNotifications, setAdminNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAdminNotificationsAPI().then(res => {
      setIsLoading(false);
      setAdminNotifications(res.body);
    }).catch(() => {
      setIsLoading(false);
    })
  }, [])

  const notificationTableData = () => {
    return adminNotifications.map((item, i) => {
      return {
        id: i,
        item: renderItem(item, i)
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Admin Notifications' parent='Mothrift' title='Admin Notifications' />
      <Container fluid={true}>
        <Row className='size-column'>
          <Col xxl='12' xl='12' md='12'>
            <Card>
              <CardBody className='card-body pt-0'>
                <div>
                  <div className='recent-activity notification mt-3'>
                    {isLoading ? <MothriftLoader /> :
                      <DataTable
                        data={notificationTableData()}
                        columns={[{ name: 'Item', selector: row => row['item'] }]}
                        center={true}
                        pagination
                        noTableHead
                        dense
                        paginationPerPage={20}
                        customStyles={customStyles}
                      />}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminNotifications;
