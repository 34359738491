import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem } from 'reactstrap';
import TransactionTable from './TransactionTable';
import SubHeader from '../../Common/SubHeader';

const Transactions = ({ data, isLoading }) => {

  return (
    <>
      <SubHeader title="Bank Deposits" data={data} />
      <Card className='tranaction-card'>
        <CardBody className='pt-4'>
          <div className={`show table-responsive recent-table transaction-table`}>
            <TransactionTable data={data.filter(item => item.type == 'Deposit')} isLoading={isLoading} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Transactions;
