export const COLORS = {

	wallet1: "#FAF5F2",
	wallet2: "#F9E4D1",
	wallet3: "#FEB476",
	button: "#FFAA63",
	normal: "#222222",

	success: "#54D969",
	finished: "#54D969",
	fail: "#FF4929",
	started: "#337bff",

	withraw: "#FF4929",
	deposit: "#54D969",

	primary: "#7366FF",
	primary2: "#71DD37",
	primary3: "#337bff",
	primary4: "#ff50a2",
	primary5: "#577bff",
	primary6: "#4053FF",
	primary7: "#00AB8C",
	primary8: "#FF4929",
	primary9: "#B94242",
	yellow: "#FFCD90",
	secondary: "#704FFE",
	danger: "#ff4a5c",
	warning: "#ffb02c",
	white: "#fff",
	info: "#4cb1ff",
	grey: '#7F7F7F',
	greybg: '#F6F6F6',
	text: "rgba(0,0,0,.8)",
	black: "#000",
	primayLight: "rgba(255,144,99,.13)",
	primayLight2: "rgba(0,196,132,.12)",
	primayLight3: "rgba(51,123,255,.15)",
	primayLight4: "rgba(255,80,162,.2)",
	primayLight5: "rgba(87,123,255,.2)",
	primayLight8: "#FF492938",
	textLight: "rgba(0,0,0,.5)",
	title: "#2F2F2F",
	dark: "#2f2f2f",
	light: "#E6E6E6",
	borderColor: "#D3D3D3",
	darkBorder: "rgba(255, 255, 255, 0.2)",
	darkBg: "#2c3f6d",
	placeholderColor: "#64646464",
	red: "#f85c6f",
	redLight: "rgba(248,92,111,.2)",
	themePrimary: "#1630C2",
	themeSecondary: "#FF74B9",
}

export const IMAGES = {
	logo_yellow: require('../assets/images/brand/logo_yellow.png'),
	logo_white: require('../assets/images/brand/logo_white.png'),
}

