import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import _ from "lodash";
import { getAllCustomersAPI } from "../../../Library/apis";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import CustomerTable from "./CustomerTable";
import UserContext from "../../../_helper/User";
import SubHeader from "../Common/SubHeader";

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(UserContext);

  const isHasRightRole =
    userInfo.roles && userInfo.roles.includes("Update Customer");

  useEffect(() => {
    updateAllCustomers();
  }, []);

  const updateAllCustomers = () => {
    setIsLoading(true);
    getAllCustomersAPI()
      .then((res) => {
        setIsLoading(false);
        setCustomers(res.customers);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Customer Management"
        parent="Mothrift"
        title="Customer Management"
      />
      <Container fluid={true}>
        <SubHeader title="All Customers" data={customers} />
        <Card className="tranaction-card">
          <CardBody className="pt-4">
            <div className={`table-responsive recent-table transaction-table`}>
              <CustomerTable
                data={customers}
                updateAllCustomers={updateAllCustomers}
                isEditable={isHasRightRole}
                isLoading={isLoading}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CustomerManagement;
