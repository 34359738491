import React, { Fragment } from 'react';

import Language from './Language';
import Searchbar from './Searchbar';
import Notificationbar from './Notificationbar';
import MoonLight from './MoonLight';
import CartHeader from './CartHeader';
import BookmarkHeader from './BookmarkHeader';
import UserHeader from './UserHeader';
import { UL } from '../../../AbstractElements';
import { Col } from 'reactstrap';
import UserContext from '../../../_helper/User';
import { useContext } from 'react';

const RightHeader = () => {
  const { userInfo } = useContext(UserContext);
  const isHasRightRole = userInfo.roles && userInfo.roles.includes("Get Notifications");
  return (
    <Fragment>
      <Col xxl='7' xl='6' md='7' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
        <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
          {/* <Searchbar /> */}
          {/* {isHasRightRole && <Notificationbar />} */}
          <UserHeader />
        </UL>
      </Col>
    </Fragment>
  );
};

export default RightHeader;
