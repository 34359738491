import React, { useEffect, useState } from 'react';
import { Col, Input, Modal, ModalHeader } from 'reactstrap';
import { Btn, H4, H5, P } from '../../../AbstractElements';

const SetBalanceModal = ({ modal, toggle, handleClick, title, color = 'primary' }) => {

    const [amount, setAmount] = useState('');

    useEffect(() => setAmount(''), [modal]);

    const handleChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^\d]*/g, '').padStart(3, '0');
        let integerPart = input.slice(0, -2);
        let decimalPart = input.slice(-2);
        let formattedInteger = parseInt(integerPart, 10).toLocaleString('en-US');
        let formattedValue = `${formattedInteger}.${decimalPart}`;
        setAmount(formattedValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            let newValue = amount.replace(/,/g, '').slice(0, -1);
            handleChange({ target: { value: newValue } });
        }
    };

    const handleCountChange = (e) => {
        let input = parseInt(e.target.value || 0);
        setAmount(input);
    };

    const isCount = title?.includes('Count');

    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggle} >
                <H4>{title}</H4>
            </ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <div>
                    <P>{'Enter desired amount below:'}</P>
                    <Col xs={12} className='p-0'>
                        {!isCount && <Input
                            className='form-control'
                            name='amount'
                            id='money'
                            type='text'
                            placeholder={'Enter amount'}
                            value={amount}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />}
                        {isCount && <Input
                            className='form-control'
                            name='amount'
                            id='money'
                            type='text'
                            placeholder={'Enter Count'}
                            value={amount}
                            onChange={handleCountChange}
                        />}
                    </Col>
                </div>
                <Btn
                    attrBtn={{
                        color: color,
                        onClick: () => { handleClick(isCount ? amount : parseFloat(amount.replaceAll(',', '')), toggle) },
                        className: 'w-100',
                        style: { height: 40, opacity: 0.8 }
                    }}>
                    {'Set Value'}
                </Btn>
            </div>
        </Modal >
    );
};

export default SetBalanceModal;
