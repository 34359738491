import React from 'react';
import { Button, Card } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { downloadCSV } from '../../../Library/custom_utils';
import { FaFileExport } from 'react-icons/fa';

const SubHeader = ({ title, data }) => {

    return (
        <Card className='p-3'>
            <div className='position-relatve'>
                <H5 attrH5={{ className: 'ms-4 mt-2 f-light font-outfit' }}>{title}</H5>
                <Button
                    className='position-absolute'
                    color='primary'
                    style={{ right: 20, top: 20 }}
                    onClick={() => { data && downloadCSV(title, data); }}
                >
                    {'Export'}
                </Button>
            </div>
        </Card>
    )

}

export default SubHeader;