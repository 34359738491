import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H4, H5 } from '../../../AbstractElements';
import SvgIcon from '../../Common/Component/SvgIcon';
import { PulseLoader } from 'react-spinners';

const Widgets1 = ({ data, isLoading }) => {

  return (
    <Card className='widget-1 position-relative'>
      <div className='position-absolute' style={{ left: 'calc(50% - 75px)', fill: 'red' }}>
        <SvgIcon className='svg-fill' iconId={`top-border-${data.color}`} />
      </div>
      <CardBody style={{ padding: '16px' }}>
        <div className=''>
          <div className='d-flex align-items-center gap-2'>
            <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', background: `${data.mainColor}27`, borderRadius: '5px' }}>
              <SvgIcon className='svg-fill' iconId={`${data.icon}`} style={{ width: '20px', height: '20px' }} />
            </div>
            <span className='text-muted'>{data.title}</span>
          </div>
          {!isLoading && <H4 attrH4={{ className: 'text-muted font32 mt-2 mb-2 ms-4' }}>{data.value}</H4>}
          {isLoading && <div className='d-flex w-100 justify-content-center align-items-center' style={{ height: 55 }}>
            <PulseLoader color={data.mainColor} size={10} />
          </div>}
        </div>
      </CardBody>
    </Card >
  );
};

export default Widgets1;
