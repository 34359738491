// dashbaord
import Crypto from '../Components/Dashboard/Crypto';
import Default from '../Components/Dashboard/Default';
import Ecommerce from '../Components/Dashboard/Ecommerce';
import OnlineCourse from '../Components/Dashboard/OnlineCourse';
import Social from '../Components/Dashboard/Social';

// //widgets
import Chart from '../Components/Widgets/Chart';
import General from '../Components/Widgets/General';

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';
// //ui-kits
import Typography from '../Components/UiKits/Typography';
import Avatars from '../Components/UiKits/Avtars';
import HelperClasses from '../Components/UiKits/HelperClass';
import Grid from '../Components/UiKits/Grid';
import TagAndPills from '../Components/UiKits/TagAndPills';
import Progressbars from '../Components/UiKits/Progress';
import Modals from '../Components/UiKits/Modals';
import Alert from '../Components/UiKits/Alert';
import Popover from '../Components/UiKits/Popover';
import Tooltips from '../Components/UiKits/Tooltip';
import Spinners from '../Components/UiKits/Spinners';
import Dropdowns from '../Components/UiKits/Dropdown';
import Accordian from '../Components/UiKits/Accordian';
import TabBootstrap from '../Components/UiKits/Tabs/BootstrapTab';
import TabLine from '../Components/UiKits/Tabs/LineTab';
import Navs from '../Components/UiKits/Navs';
import Shadow from '../Components/UiKits/Shadows';
import List from '../Components/UiKits/Lists';

// //Bonus-ui
import Scrollable from '../Components/Bonus-Ui/Scrollable';
import TreeView from '../Components/Bonus-Ui/TreeView';
import BootstrapNotify from '../Components/Bonus-Ui/BootstrapNotify';
import RatingClass from '../Components/Bonus-Ui/Rating';
import Dropzones from '../Components/Bonus-Ui/Dropzones';
import Tours from '../Components/Bonus-Ui/Tour';
import SweetAlerts from '../Components/Bonus-Ui/SweetAlert';
import Carousels from '../Components/Bonus-Ui/Carousel';
import Ribbons from '../Components/Bonus-Ui/Ribbons';
import Paginations from '../Components/Bonus-Ui/Pagination';
import Steps from '../Components/Bonus-Ui/Steps';
import BreadcrumbClass from '../Components/Bonus-Ui/Breadcrumb';
import RangeSlider from '../Components/Bonus-Ui/RangeSlider';
import Imagecropper from '../Components/Bonus-Ui/ImageCropper';
import Sticky from '../Components/Bonus-Ui/Sticky';
import DragAndDrop from '../Components/Bonus-Ui/DragAndDrop';
import UploadImage from '../Components/Bonus-Ui/ImageUpload';
import BasicCards from '../Components/Bonus-Ui/Cards/BasicCards/index';
import CreativeCards from '../Components/Bonus-Ui/Cards/CreativeCards';
import TabCard from '../Components/Bonus-Ui/Cards/TabCard';
import DraggingCards from '../Components/Bonus-Ui/Cards/DragCards/index';
import Timeline1 from '../Components/Bonus-Ui/Timeline/Timeline1';
// // Icons
import WeatherIcon from '../Components/Icons/WeatherIcon';
import FeatherIcon from '../Components/Icons/FeatherIcon';
import ThemifyIcon from '../Components/Icons/ThemifyIcon';
import IcoIcon from '../Components/Icons/IcoIcon';
import FontawesomeIcon from '../Components/Icons/FontawesomeIcon';
import FlagIcon from '../Components/Icons/FlagIcon';

// // Buttons
import DefaultButton from '../Components/Buttons/Default';
import FlatButton from '../Components/Buttons/FlatBtn/index';
import EdgeButton from '../Components/Buttons/Edge/index';
import RaisedButton from '../Components/Buttons/RaisedBtn/index';
import GroupButton from '../Components/Buttons/GroupBtn/index';

// //chart
import ApexCharts from '../Components/Charts/ApexCharts';
import Chartist from '../Components/Charts/ChartistCharts';
import ChartJs from '../Components/Charts/ChartsJs';
import GoogleCharts from '../Components/Charts/GoogleCharts';

// //forms
import FormValidation from '../Components/Forms/FormControl/FormValidation';
import BaseInput from '../Components/Forms/FormControl/BaseInput';
import CheckboxandRadio from '../Components/Forms/FormControl/CheckRadio';
import InputGroups from '../Components/Forms/FormControl/InputGroups';
import MegaOption from '../Components/Forms/FormControl/MegaOption';
import Datepicker from '../Components/Forms/FormWidget/DatePicker';
import Timepicker from '../Components/Forms/FormWidget/TimepickerComponent/TimePicker';
import TypeaheadComp from '../Components/Forms/FormWidget/TypeaheadComponent/Typeahead';
import FormDefault from '../Components/Forms/FormLayout/Default';
import FormWizard from '../Components/Forms/FormLayout/FormWizard1/FormWizard1';
import BasicTables from '../Components/Tables/Reactstrap/BasicTable';

import DataTables from '../Components/Tables/DataTable';

// //Application
import Newproject from '../Components/Application/Project/Newproject';
import Project from '../Components/Application/Project/Project';
import Chat from '../Components/Application/Chat/ChatApp';
import VideoChat from '../Components/Application/Chat/VideoChat';

import Contact from '../Components/Application/Contact/ContactApp';
import Task from '../Components/Application/Task';
import TodoContain from '../Components/Application/Todo';

// //Gallary
import ImageGallery from '../Components/Gallery/ImageGallery';
import MesonryDesc from '../Components/Gallery/MesonryDesc';
import MasonryGallery from '../Components/Gallery/MesonryGallery';
import ImageWithDesc from '../Components/Gallery/ImageWithDesc';
import ImageHover from '../Components/Gallery/ImageHover';

// //Blog
import BlogDetail from '../Components/Blog/BlogDetail';
import BlogPost from '../Components/Blog/BlogPost';
import BlogSingle from '../Components/Blog/BlogSingle';

// //Pages
import SamplePage from '../Components/Pages/Sample';
// // import SearchPage from '../Components/Application/SearchResult'

// //Book mark
import BookmarksContain from '../Components/Application/BookMarks';

// //User app
import UsersCardssContain from '../Components/Application/Users/UsersCards';
import UsersEditContain from '../Components/Application/Users/UsersEdit';
import UsersProfileContain from '../Components/Application/Users/UsersProfile';

// //social app
import SocialAppContain from '../Components/Application/SocialApp';

// //calender
// import CalenderContain from '../Components/Application/Calender/Index';

// //Email
import MailInboxContain from '../Components/Application/Email/MailInbox';

// //File Manager
import FileManagerContain from '../Components/Application/FileManager';
import SearchResultContain from '../Components/Application/SearchResult';

// //E-commerce
import Products from '../Components/Application/Ecommerce/Products';
import ProductCart from '../Components/Application/Ecommerce/ProductCart';
import WishList from '../Components/Application/Ecommerce/Wishlist';
import CheckOut from '../Components/Application/Ecommerce/CheckOut';
import Invoice from '../Components/Application/Ecommerce/Invoice';
import OrderHistory from '../Components/Application/Ecommerce/OrderHistory';
import ProductPage from '../Components/Application/Ecommerce/ProductPage';
import PricingMemberShip from '../Components/Application/Ecommerce/PricingMemberShip';
import PaymentDetails from '../Components/Application/Ecommerce/PaymentDetails';
import ProductListContain from '../Components/Application/Ecommerce/ProductList';
import KanbanBoardContain from '../Components/Application/KanbanBoard';
import DraggableContain from '../Components/Application/Calender/DraggableCalender';

// //Job Search
import JobCards from '../Components/JobSearch/JobCardView';
import ListView from '../Components/JobSearch/ListView';
import JobDetails from '../Components/JobSearch/JobDetail';
import Apply from '../Components/JobSearch/Apply';

// //Learning
import LearningList from '../Components/Learning/LearningList';
import DetailCourse from '../Components/Learning/DetailCourse';

// //Map
import GoogleMaps from '../Components/Map/GoogleMap';
import PigeonMap from '../Components/Map/Pigeon Map';

// //Editor
import CkEditorContain from '../Components/Editor/CkEditor';
import MdeEDitorContain from '../Components/Editor/MDEEditor/MDEEditor';
import ACEEditorContain from '../Components/Editor/AceCodeEditor/ACECodeEditor';

import KnowledgeBaseContain from '../Components/Knowledgebase/KnowledgeBase';
import KnowledgeCategoryContain from '../Components/Knowledgebase/KnowledgeCategory';
import KnowledgeDetailContain from '../Components/Knowledgebase/KnowledgeDetails';
import SupportTickitContain from '../Components/SupportTicket';
import DateTimeContain from '../Components/Forms/FormWidget/DateandTime';
import FormDateRangepickerContain from '../Components/Forms/FormWidget/FormDateRangePicker';
import FormSelectContain from '../Components/Forms/FormWidget/FormSelect2';
import FormSwitchContain from '../Components/Forms/FormWidget/FormSwitch';
import FormTouchspinContain from '../Components/Forms/FormWidget/FormTouchspin';
import FormClipboardContain from '../Components/Forms/FormWidget/FormClipboard';
import FAQContent from '../Components/FAQ';
import Home from '../Components/Mothrift/Home';
import EditProfile from '../Components/Mothrift/AccountSettings/EditProfile';
import BalanceInfo from '../Components/Mothrift/BalanceInfo';
import Customers from '../Components/Mothrift/ReportsAndRecords/Customers';
import BankTransfers from '../Components/Mothrift/ReportsAndRecords/BankTransfers';
import Savings from '../Components/Mothrift/ReportsAndRecords/Savings';
import TermSavings from '../Components/Mothrift/ReportsAndRecords/TermSavings';
import Interests from '../Components/Mothrift/ReportsAndRecords/Interests';
import AllStaff from '../Components/Mothrift/StaffMagement/AllStaff';
import StaffPositions from '../Components/Mothrift/StaffMagement/StaffPositions';
import CustomerManagement from '../Components/Mothrift/CustomerManagement';
import CustomerNotifications from '../Components/Mothrift/Notifications/CustomerNotifications';
import AdminNotifications from '../Components/Mothrift/Notifications/AdminNotifications';
import PendingWithdrawalPage from '../Components/Mothrift/ReportsAndRecords/PendingWithdrawls';
import Airtimes from '../Components/Mothrift/ReportsAndRecords/Airtimes';

const MothriftRoutes = [
  { path: '/mothrift/home', Component: <Home /> },
  { path: '/mothrift/notifications/customer-notifications', Component: <CustomerNotifications /> },
  { path: '/mothrift/notifications/admin-notifications', Component: <AdminNotifications /> },
  { path: '/mothrift/financial-settings', Component: <BalanceInfo /> },
  { path: '/mothrift/reports-records/customers', Component: <Customers /> },
  { path: '/mothrift/reports-records/bank-transfers', Component: <BankTransfers /> },
  { path: '/mothrift/reports-records/bank-deposits', Component: <Savings /> },
  { path: '/mothrift/reports-records/term-savings', Component: <TermSavings /> },
  { path: '/mothrift/reports-records/interests', Component: <Interests /> },
  { path: '/mothrift/reports-records/airtimes', Component: <Airtimes /> },
  { path: '/mothrift/pending-withdrawals', Component: <PendingWithdrawalPage /> },
  { path: '/mothrift/customer-management', Component: <CustomerManagement /> },
  { path: '/mothrift/staff-management/all-staff', Component: <AllStaff /> },
  { path: '/mothrift/staff-management/staff-positions', Component: <StaffPositions /> },
  { path: '/mothrift/account-settings/profile', Component: <EditProfile /> },
];

const AllRoutes = [
  // mothrift
  { path: '/mothrift/home', Component: <Home /> },
  { path: '/mothrift/balance-info', Component: <BalanceInfo /> },
  { path: '/mothrift/reports-records/customers', Component: <Customers /> },
  { path: '/mothrift/reports-records/bank-transfers', Component: <BankTransfers /> },
  { path: '/mothrift/reports-records/savings', Component: <Savings /> },
  { path: '/mothrift/reports-records/term-savings', Component: <TermSavings /> },
  { path: '/mothrift/reports-records/interests', Component: <Interests /> },
  { path: '/mothrift/notifications/customer-notifications', Component: <CustomerNotifications /> },
  { path: '/mothrift/notifications/admin-notifications', Component: <AdminNotifications /> },
  { path: '/mothrift/customer-management', Component: <CustomerManagement /> },
  { path: '/mothrift/staff-management/all-staff', Component: <AllStaff /> },
  { path: '/mothrift/staff-management/staff-positions', Component: <StaffPositions /> },
  { path: '/mothrift/account-settings/profile', Component: <EditProfile /> },

  //dashboard
  { path: '/dashboard/default', Component: <Default /> },
  { path: '/dashboard/e-commerce', Component: <Ecommerce /> },
  { path: '/dashboard/online-course', Component: <OnlineCourse /> },
  { path: '/dashboard/crypto', Component: <Crypto /> },
  { path: '/dashboard/social', Component: <Social /> },

  // // //widgets
  { path: '/widgets/general', Component: <General /> },
  { path: '/widgets/chart', Component: <Chart /> },

  // // //page-layout
  // { path: '/page-layout/footer-light', Component: <FooterLight /> },
  // { path: '/page-layout/footer-fixed', Component: <FooterFixed /> },
  // { path: '/page-layout/footer-dark', Component: <FooterDark /> },

  // // //ui-kits
  { path: '/ui-kits/typography', Component: <Typography /> },
  { path: '/ui-kits/avatar', Component: <Avatars /> },
  { path: '/ui-kits/helper-class', Component: <HelperClasses /> },
  { path: '/ui-kits/grids', Component: <Grid /> },
  { path: '/ui-kits/tag-pills', Component: <TagAndPills /> },
  { path: '/ui-kits/progress', Component: <Progressbars /> },
  { path: '/ui-kits/modal', Component: <Modals /> },
  { path: '/ui-kits/alert', Component: <Alert /> },
  { path: '/ui-kits/popover', Component: <Popover /> },
  { path: '/ui-kits/tooltips', Component: <Tooltips /> },
  { path: '/ui-kits/spinner', Component: <Spinners /> },
  { path: '/ui-kits/dropdown', Component: <Dropdowns /> },
  { path: '/ui-kits/accordion', Component: <Accordian /> },
  { path: '/ui-kits/tabs/bootstrap', Component: <TabBootstrap /> },
  { path: '/ui-kits/tabs/line', Component: <TabLine /> },
  { path: '/ui-kits/navs', Component: <Navs /> },
  { path: '/ui-kits/shadow', Component: <Shadow /> },
  { path: '/ui-kits/list', Component: <List /> },

  // // //Bonus Ui
  { path: '/bonus-ui/scrollable', Component: <Scrollable /> },
  { path: '/bonus-ui/tree-view', Component: <TreeView /> },
  { path: '/bonus-ui/bootstrap-notify', Component: <BootstrapNotify /> },
  { path: '/bonus-ui/rating', Component: <RatingClass /> },
  { path: '/bonus-ui/dropzone', Component: <Dropzones /> },
  { path: '/bonus-ui/tour', Component: <Tours /> },
  { path: '/bonus-ui/sweet-alert', Component: <SweetAlerts /> },
  { path: '/bonus-ui/carousel', Component: <Carousels /> },
  { path: '/bonus-ui/ribbons', Component: <Ribbons /> },
  { path: '/bonus-ui/pagination', Component: <Paginations /> },
  { path: '/bonus-ui/steps', Component: <Steps /> },
  { path: '/bonus-ui/breadcrumb', Component: <BreadcrumbClass /> },
  { path: '/bonus-ui/rangeslider', Component: <RangeSlider /> },
  { path: '/bonus-ui/imagecropper', Component: <Imagecropper /> },
  { path: '/bonus-ui/stickynotes', Component: <Sticky /> },
  { path: '/bonus-ui/drag_and_drop', Component: <DragAndDrop /> },
  { path: '/bonus-ui/image-upload', Component: <UploadImage /> },
  { path: '/bonus-ui/card/basiccards', Component: <BasicCards /> },
  { path: '/bonus-ui/card/creativecards', Component: <CreativeCards /> },
  { path: '/bonus-ui/card/tabcard', Component: <TabCard /> },
  { path: '/bonus-ui/card/draggingcards', Component: <DraggingCards /> },
  { path: '/bonus-ui/timelines/timeline1', Component: <Timeline1 /> },

  // // //Icons
  { path: '/icons/flag_icons', Component: <FlagIcon /> },
  { path: '/icons/fontawesome_icon', Component: <FontawesomeIcon /> },
  { path: '/icons/ico_icon', Component: <IcoIcon /> },
  { path: '/icons/themify_icons', Component: <ThemifyIcon /> },
  { path: '/icons/feather_icons', Component: <FeatherIcon /> },
  { path: '/icons/weather_icons', Component: <WeatherIcon /> },

  // // //Buttons
  { path: '/buttons/simplebutton', Component: <DefaultButton /> },
  { path: '/buttons/flat', Component: <FlatButton /> },
  { path: '/buttons/edge', Component: <EdgeButton /> },
  { path: '/buttons/raised', Component: <RaisedButton /> },
  { path: '/buttons/group', Component: <GroupButton /> },

  // // //Charts
  { path: '/charts/apex', Component: <ApexCharts /> },
  { path: '/charts/chartist', Component: <Chartist /> },
  { path: '/charts/chartJs', Component: <ChartJs /> },
  { path: '/charts/google', Component: <GoogleCharts /> },

  // // // Forms
  { path: '/forms/controls/validation', Component: <FormValidation /> },
  { path: '/forms/controls/input', Component: <BaseInput /> },
  { path: '/forms/controls/radio-checkbox', Component: <CheckboxandRadio /> },
  { path: '/forms/controls/group', Component: <InputGroups /> },
  { path: '/forms/controls/megaoption', Component: <MegaOption /> },
  { path: '/forms/widget/datepicker', Component: <Datepicker /> },
  { path: '/forms/widget/timepicker', Component: <Timepicker /> },
  { path: '/forms/widget/typeahead', Component: <TypeaheadComp /> },
  { path: '/forms/widget/datetimepicker', Component: <DateTimeContain /> },
  { path: '/forms/widget/rangepicker', Component: <FormDateRangepickerContain /> },
  { path: '/forms/widget/select2', Component: <FormSelectContain /> },
  { path: '/forms/widget/switch', Component: <FormSwitchContain /> },
  { path: '/forms/widget/touchspin', Component: <FormTouchspinContain /> },
  { path: '/forms/widget/clipboard', Component: <FormClipboardContain /> },

  { path: '/forms/layout/formDefault', Component: <FormDefault /> },
  { path: '/forms/layout/formWizard', Component: <FormWizard /> },

  // //Tables
  { path: '/table/reactstraptable/basictable', Component: <BasicTables /> },
  { path: '/table/datatable', Component: <DataTables /> },

  // //Applicatiion
  { path: '/app/project/project-list', Component: <Project /> },
  { path: '/app/project/new-project', Component: <Newproject /> },
  { path: '/app/chat-app/chats', Component: <Chat /> },
  { path: '/app/chat-app/chat-video-app', Component: <VideoChat /> },
  { path: '/app/contact-app/contacts', Component: <Contact /> },

  { path: '/app/task', Component: <Task /> },
  { path: '/app/bookmark', Component: <BookmarksContain /> },
  { path: '/app/todo-app/todo', Component: <TodoContain /> },

  { path: '/app/users/profile', Component: <UsersProfileContain /> },
  { path: '/app/users/edit', Component: <UsersEditContain /> },
  { path: '/app/users/cards', Component: <UsersCardssContain /> },
  { path: '/app/social-app', Component: <SocialAppContain /> },

  { path: '/app/calendar/draggable-calendar', Component: <DraggableContain /> },

  { path: '/app/email-app', Component: <MailInboxContain /> },
  { path: '/app/file-manager', Component: <FileManagerContain /> },
  { path: '/app/search', Component: <SearchResultContain /> },
  { path: '/app/ecommerce/product', Component: <Products /> },
  { path: '/app/ecommerce/product-page/:id', Component: <ProductPage /> },
  { path: '/app/ecommerce/payment-details', Component: <PaymentDetails /> },
  { path: '/app/ecommerce/orderhistory', Component: <OrderHistory /> },
  { path: '/app/ecommerce/pricing', Component: <PricingMemberShip /> },
  { path: '/app/ecommerce/invoice', Component: <Invoice /> },
  { path: '/app/ecommerce/cart', Component: <ProductCart /> },
  { path: '/app/ecommerce/wishlist', Component: <WishList /> },
  { path: '/app/ecommerce/checkout', Component: <CheckOut /> },
  { path: '/app/ecommerce/product-list', Component: <ProductListContain /> },
  { path: '/app/kanban-board', Component: <KanbanBoardContain /> },

  // //Gallery
  { path: '/app/gallery/grids', Component: <ImageGallery /> },
  { path: '/app/gallery/griddesc', Component: <ImageWithDesc /> },
  { path: '/app/gallery/masonrys', Component: <MasonryGallery /> },
  { path: '/app/gallery/masonrydesc', Component: <MesonryDesc /> },
  { path: '/app/gallery/hover_effect', Component: <ImageHover /> },

  // //page
  { path: '/pages/sample-page', Component: <SamplePage /> },

  // //Blog
  { path: '/app/blog/blogdetails', Component: <BlogDetail /> },
  { path: '/app/blog/blogsingle', Component: <BlogSingle /> },
  { path: '/app/blog/blogpost', Component: <BlogPost /> },

  // //Job Search
  { path: '/app/jobsearch/cardview', Component: <JobCards /> },
  { path: '/app/jobsearch/joblist', Component: <ListView /> },
  { path: '/app/jobsearch/jobdetail', Component: <JobDetails /> },
  { path: '/app/jobsearch/jobapply', Component: <Apply /> },

  // //Learning
  { path: '/app/learning/learninglist', Component: <LearningList /> },
  { path: '/app/learning/learningdetail', Component: <DetailCourse /> },

  // //Map
  { path: '/app/map/googlemap', Component: <GoogleMaps /> },
  { path: '/app/map/pigeonmap', Component: <PigeonMap /> },

  // //Editor
  { path: '/editor/ckeditor', Component: <CkEditorContain /> },
  { path: '/editor/mdeeditor', Component: <MdeEDitorContain /> },
  { path: '/editor/aceeditor', Component: <ACEEditorContain /> },

  // //Knowledge
  { path: '/app/knowledgebase', Component: <KnowledgeBaseContain /> },
  { path: '/app/knowledgebase-category', Component: <KnowledgeCategoryContain /> },
  { path: '/app/knowledgebase-detail', Component: <KnowledgeDetailContain /> },

  // //Support Ticket
  { path: '/app/supportticket', Component: <SupportTickitContain /> },

  // //Faq
  { path: '/app/faq', Component: <FAQContent /> },
];

const isLocalhost = window.location.hostname == 'localhost';
// export const routes = isLocalhost ? AllRoutes : MothriftRoutes;
export const routes = MothriftRoutes;
