import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H6, P, Image, H1 } from '../../../../AbstractElements';
import logoWhite from '../../../../assets/images/logo/logo.png';
import logoDark from '../../../../assets/images/logo/logo_dark.png';
import { confirmOTPCodeAPI, resetAdminPasswordAPI, setPasswordAPI } from '../../../../Library/apis';
import { toast } from 'react-toastify';
import MothriftLogo from '../../Common/MothriftLogo';

const ForgetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [checkOPT, setCheckOPT] = useState(false);

  const [sendButton, setSendButton] = useState('Send')
  const [userInfo, setUserInfo] = useState('');
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const handleSend = () => {
    console.log(userInfo)
    resetAdminPasswordAPI({ userInfo }).then(res => {
      toast.success(res.message, { autoClose: 1000 });
      if (sendButton == 'Send') setSendButton('Resend');
    }).catch((error) => {
      toast.error(error?.response?.data?.message || 'Reset Code Sending error', { autoClose: 1000 });
    })
  }

  const handleConfirmOPTCode = () => {
    confirmOTPCodeAPI({ userInfo, code }).then(res => {
      toast.success(res.message, { autoClose: 1000 });
      setToken(res.token);
      setCheckOPT(true);
    }).catch(error => {
      toast.error(error?.response?.data?.message || 'Code Veirification error', { autoClose: 1000 });
    })
  }

  const handleSubmit = (e) => {
    e && e.preventDefault();
    handleSend();
  }

  const handleChangePassword = () => {
    setPasswordAPI({ newPassword, token }).then(res => {
      toast.success(res.message, { autoClose: 1000 });
      navigate('/login');
    }).catch(error => {
      toast.error(error?.response?.data?.message || 'Password Reset error', { autoClose: 1000 });
    })
  }

  return (
    <Fragment>
      <MothriftLogo size={220} white />
      <div style={{ display: 'flex', alignItems: 'center', height: '100vh', background: '#fafafa' }}>
        <div style={{ background: '#11131F', width: '35%', height: '100%', marginRight: '130px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <H1 attrH1={{ className: 'text-center font-outfit font60-bold', style: { color: '#7366FF' } }}>{'Mothrift Backend'}</H1>
        </div>
        <Card className='p-4 font-outfit' style={{ width: '400px' }}>
          <Form className='theme-form login-form' onSubmit={handleSubmit}>
            <H4 attrH4={{ className: 'text-primary text-center' }}>Reset Your Password</H4>

            <FormGroup>
              <Label className='m-0 col-form-label'>Email or Phone Number</Label>
              <Input className='form-control' type='text' required placeholder='Input your user information' value={userInfo} onChange={(e) => setUserInfo(e.target.value)} />
            </FormGroup>

            <FormGroup className='text-end'>
              <Btn attrBtn={{ className: 'btn-block ', color: 'primary', type: 'submit' }}>{sendButton}</Btn>
            </FormGroup>

            {!checkOPT && <>
              <FormGroup>
                <Label>Enter OTP</Label>
                <Input className='form-control text-right opt-text' placeholder='000000' type='text' value={code} onChange={(e) => setCode(e.target.value)} />
              </FormGroup>

              <FormGroup className='text-end'>
                <Btn attrBtn={{ className: 'btn-block ', color: 'primary', onClick: handleConfirmOPTCode }}>{'Verify'}</Btn>
              </FormGroup>
            </>}

            {checkOPT && <>
              <H6 attrH6={{ className: 'mt-4' }}>Create Your Password</H6>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0'>New Password</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'} required placeholder='*********' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <div className='show-hide' style={{ top: 23 }} onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={`{${togglePassword} ? '' : 'show'} text-primary`}></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ color: 'primary', className: 'btn d-block w-100', onClick: handleChangePassword }}>Done</Btn>
              </FormGroup>
            </>}

            <P attrPara={{ className: 'text-center mb-0 mt-3' }}>
              Already have an password?
              <Link className='ms-2 text-primary' to={`/login`}>
                Log In
              </Link>
            </P>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
};

export default ForgetPwd;
