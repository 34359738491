import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import Avatar from "react-avatar";
import { Badges, Btn } from "../../../AbstractElements";
import { disableUserAPI, terminateUserAPI } from "../../../Library/apis";
import { toast } from "react-toastify";
import { formatCurrency } from "../../../Library/custom_utils";
import { Spinner } from "reactstrap";
import MothriftLoader from "../Common/MothriftLoader";
import Swal from "sweetalert2";
const tableColumns = [
  {
    name: "User Name/ID",
    selector: (row) => row["name"],
    width: "180px",
    center: false,
  },
  {
    name: "Savings Wallet",
    selector: (row) => row["savings"],
    width: "150px",
    center: false,
  },
  {
    name: "Term Savings Wallet",
    selector: (row) => row["termsavings"],
    width: "150px",
    center: false,
  },
  {
    name: "Interest Wallet",
    selector: (row) => row["interest"],
    center: false,
  },
  {
    name: "Phone Number",
    selector: (row) => row["phoneNumber"],
    center: true,
  },
  {
    name: "Email Address",
    selector: (row) => row["emailAddress"],
    width: "250px",
    center: true,
  },
  {
    name: "Verified",
    selector: (row) => row["confirmed"],
    width: "120px",
    center: true,
  },
  {
    name: "Deleted",
    selector: (row) => row["delete"],
    width: "80px",
    center: true,
  },
  {
    name: "Action",
    selector: (row) => row["activate"],
    width: "200px",
    center: true,
  },
];

const RenderItem = (item, updateAllCustomers, isEditable) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTerminating, setIsTerminating] = useState(false);

  const handleDisable = () => {
    setIsDeleting(true);
    disableUserAPI({ userId: item.userInfo._id })
      .then((res) => {
        toast.success(res.message, { autoClose: 1000 });
        updateAllCustomers();
        setIsDeleting(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "User Disable Error", {
          autoClose: 1000,
        });
        setIsDeleting(false);
      });
  };

  const handleTerminate = () => {
    Swal.fire({
      title: "Do you want to cancel this Term Savings?",
      text: "If yes, you will not earn any interest for uncompleted Term Savings",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsTerminating(true);
        terminateUserAPI({
          userId: item.userInfo._id,
          amount: item.termSavingsWallet,
        })
          .then((res) => {
            toast.success(res.message, { autoClose: 1000 });
            updateAllCustomers();
            setIsTerminating(false);
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.message || "User Terminate Error",
              {
                autoClose: 1000,
              }
            );
            setIsTerminating(false);
          });
      }
    });
  };

  const userName = `${item.userInfo.firstName} ${item.userInfo.lastName}`;
  return {
    name: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar
          style={{ overflow: "unset" }}
          name={userName}
          size="40"
          textMarginRatio={0.3}
          round
          src={item.userInfo.profileImageUrl}
          color="#7366FF"
        />
        <div
          className="media-body ms-2 d-flex"
          style={{ flexDirection: "column", gap: 3 }}
        >
          <span className="f-w-700 font-dark text-wrap text-center">
            {userName}
          </span>
          <span className="f-w-700 font-info text-wrap text-center">
            {item.userInfo.userId}
          </span>
        </div>
      </div>
    ),
    savings: (
      <span className="f-w-700 font-warning">
        {formatCurrency(item.savingsWallet)}
      </span>
    ),
    termsavings: (
      <span className="f-w-700 font-warning">
        {formatCurrency(item.termSavingsWallet)}
      </span>
    ),
    interest: (
      <span className="f-w-700 font-warning">
        {formatCurrency(item.interestWallet)}
      </span>
    ),
    phoneNumber: (
      <span className="f-w-700 font-dark">{item.userInfo.phoneNumber}</span>
    ),
    emailAddress: (
      <span className="f-w-700 font-dark">{item.userInfo.email}</span>
    ),
    confirmed: (
      <Badges
        attrBadge={{
          className: "badge rounded-pill",
          color: item.userInfo.confirmed ? "info" : "success",
          pill: true,
        }}
      >
        {item.userInfo.confirmed ? "confirmed" : "pending"}
      </Badges>
    ),
    delete: (
      <Badges
        attrBadge={{
          className: "badge rounded-pill",
          color: item.userInfo.deleted ? "danger" : "warning",
          pill: true,
        }}
      >
        {item.userInfo.deleted ? "deleted" : "None"}
      </Badges>
    ),
    activate: isEditable ? (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <Btn
          attrBtn={{
            color: item.userInfo.active ? "secondary" : "info",
            size: "sm",
            active: true,
            outline: true,
            onClick: handleDisable,
            style: { borderRadius: 30, padding: "2px 10px 2px 10px" },
          }}
        >
          {isDeleting && <Spinner size="sm" color="light" className="me-2" />}
          {item.userInfo.active ? "Disable" : "Enable"}
        </Btn>
        {item.termSavingsWallet > 0 && (
          <Btn
            attrBtn={{
              color: "danger",
              size: "sm",
              active: true,
              outline: true,
              onClick: handleTerminate,
              style: { borderRadius: 30, padding: "2px 10px 2px 10px" },
            }}
          >
            {isTerminating && (
              <Spinner size="sm" color="light" className="me-2" />
            )}
            Terminate
          </Btn>
        )}
      </div>
    ) : (
      <Badges
        attrBadge={{
          className: "badge rounded-pill",
          color: item.userInfo.active ? "primary" : "secondary",
          pill: true,
        }}
      >
        {item.userInfo.active ? "enabled" : "disabled"}
      </Badges>
    ),
  };
};

const CustomerTable = ({ data, updateAllCustomers, isEditable, isLoading }) => {
  return (
    <Fragment>
      {isLoading ? (
        <MothriftLoader />
      ) : (
        <DataTable
          data={data.map((item) =>
            RenderItem(item, updateAllCustomers, isEditable)
          )}
          columns={tableColumns}
          center
          pagination
          paginationPerPage={20}
        />
      )}
    </Fragment>
  );
};
export default CustomerTable;
