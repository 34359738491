import React, { useEffect, useState } from 'react';
import Context from './index';

const GoogleChartProvider = (props) => {
  const [googleChart, setGoogleChart] = useState([]);

  return (
    <Context.Provider
      value={{
        ...props,
        googleChart,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default GoogleChartProvider;