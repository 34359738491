import React, { useContext, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner } from 'reactstrap'
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Btn, H4 } from '../../../../AbstractElements';
import { addStaffAPI } from "../../../../Library/apis";
import UserContext from "../../../../_helper/User";

const AddStaffsModal = ({ modal, toggle, updateStaffList }) => {

    const { handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        positionId: ""
    });

    const { staffRoles } = useContext(UserContext);

    const onEditSubmit = (data) => {
        setIsLoading(true);
        addStaffAPI(formValues).then(res => {
            toast.success(res.message);
            updateStaffList();
            toggle();
            setIsLoading(false);
        }).catch(e => {
            toast.error(e?.response?.data?.message || 'Add Staff Error', { autoClose: 1000 });
            setIsLoading(false);
        });
    }
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const handleSelectChange = (e) => {
        setFormValues(({ ...formValues, positionId: e.target.value }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >
                <H4>{'Add New Staff'}</H4>
            </ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Form onSubmit={handleSubmit(onEditSubmit)}>
                    <Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Middle Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.middleName || ""}
                                    name="middleName"
                                    onChange={handleChange}
                                    placeholder="Middle Name"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Sure Name"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    required />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Staff Position'}</Label>
                                <Input type="select" name="select" className="form-control btn-square" onChange={handleSelectChange}>
                                    <option value={''} key={-1}>{'Select'}</option>
                                    {staffRoles.map((items, i) =>
                                        <option value={items._id} key={i}>{items.positionName}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Btn attrBtn={{ color: 'primary', onClick: onEditSubmit, className: 'w-100 font18', style: { height: 40 } }}>
                    {isLoading && <Spinner size="sm" color="light" className='me-2' />}
                    {isLoading ? 'Adding' : '+ Add New Staff'}
                </Btn>
            </div>
        </Modal >
    );
};

export default AddStaffsModal;
