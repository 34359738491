import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Widgets1 from './Widgets1';
import { H3 } from '../../../AbstractElements';

const TotalUser = ({ data, isLoading }) => {

  const DATA = [
    {
      title: 'Total Staffs',
      color: 'warning',
      mainColor: '#F3C568',
      icon: 'top-staff',
      value: data.totalAdminUser,
    },
    {
      title: 'Total Customers',
      color: 'secondary',
      mainColor: '#FF37B1',
      icon: 'top-customers',
      value: data.totalCustomerUser,
    },
  ]

  return (
    <Container>
      <H3 attrH3={{ className: 'mb-3 f-light' }}>{'Total Users'}</H3>
      <Row>
        {DATA.map((item, i) => <Col key={`userInfo_${i}`} xxl='3' xl={4} lg={6} sm={12}>
          <Widgets1 data={item} isLoading={isLoading} />
        </Col>)}
      </Row>
    </Container>
  );
};

export default TotalUser;
