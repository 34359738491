import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { Badges, H3, H6 } from '../../../../AbstractElements';
import { TrendingDown, TrendingUp } from 'react-feather';
import { convertDate, formatCurrency, getRemainingPercentage, getTermSavingsEndDate } from '../../../../Library/custom_utils';
import { Progress } from 'reactstrap';
import MothriftLoader from '../../Common/MothriftLoader';

const TermSavingsTableData = ({ data: defaultData, isLoading }) => {

  const tableColumns = [
    {
      name: 'User ID',
      selector: row => row['user'],
      width: '140px',
      sortable: true,
      center: false,
    },
    {
      name: 'Amount',
      selector: row => row['amount'],
      sortable: true,
      center: false,
    },
    {
      name: 'Term',
      selector: row => row['term'],
      sortable: true,
      center: false,
    },
    {
      name: 'Date Started',
      selector: row => row['startDate'],
      sortable: true,
      center: true,
    },
    {
      name: 'End Date',
      selector: row => row['endDate'],
      sortable: true,
      center: true,
    },
    {
      name: 'Progress',
      selector: row => row['progress'],
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: row => row['status'],
      sortable: true,
      center: true,
    },
  ];

  const renderItem = (item) => {

    const color = item.status == 'Started' ? 'info' : item.status == 'Finished' ? 'success' : 'danger'
    const term = item?.term == 1 ? '2 hours' : `${item.term} days`;

    return {
      user: <H6 attrH6={{ className: 'f-14 f-light' }}>{item.userId}</H6>,
      amount: <H3 attrH3={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.amount)}</H3>,
      term: <H6 attrH6={{ className: 'f-14 mb-2 f-light' }}>{term}</H6>,
      startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate(item.createdAt)}</H6>,
      endDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{getTermSavingsEndDate(item)}</H6>,
      progress: <div style={{ minWidth: '200px' }}>
        <Progress className='sm-progress-bar' color={color} value={getRemainingPercentage(item.term, item.createdAt, item.status, item.updatedAt)} style={{ height: '5px' }} />
      </div>,
      status: <Badges attrBadge={{ className: 'badge rounded-pill', color: color, pill: true }}>{item.status}</Badges>,
    }
  }

  return (
    <Fragment>
      {isLoading ? <MothriftLoader /> :
        <DataTable
          data={defaultData.map(item => renderItem(item))}
          columns={tableColumns}
          striped
          center
          paginationPerPage={20}
          pagination
        />}
    </Fragment>
  )
}
export default TermSavingsTableData