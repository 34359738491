import React, { Fragment, useContext, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, CardBody, Button, CardFooter, ModalBody } from 'reactstrap'
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Btn, H4 } from '../../../../AbstractElements';
import makeAnimated from 'react-select/animated';
import { createStaffRoleAPI } from "../../../../Library/apis";
import UserContext from "../../../../_helper/User";
import Select from 'react-select';

const animatedComponents = makeAnimated();

const AddPositionModal = ({ modal, toggle, roles }) => {

    const { updateStaffRoles } = useContext(UserContext);

    const [formValues, setFormValues] = useState({
        positionName: "",
        roles: [],
    });

    const onEditSubmit = () => {

        if (!formValues.positionName || formValues.roles.length <= 0) return;

        createStaffRoleAPI({ positionName: formValues.positionName, roles: formValues.roles.map(role => role.label) }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            setFormValues({ positionName: "", roles: [], });
            updateStaffRoles();
            toggle();
        }).catch(error => {
            const message = error?.response?.data?.message || 'Add Staff Role Error';
            toast.error(message, { autoClose: 1000 });
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSelectChange = (selectedOptions) => {
        setFormValues(prevValues => ({ ...prevValues, roles: selectedOptions }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >
                <H4>{'Add New Staff Position'}</H4>
            </ModalHeader>
            <ModalBody>
                <FormGroup className="mb-3">
                    <Label className="form-label">{'Positon Name'}</Label>
                    <Input
                        className="form-control"
                        type="text"
                        placeholder="Position Name *"
                        name="positionName"
                        required
                        value={formValues.positionName || ""}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="form-label">{'Staff Position'}</Label>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={roles}
                        className="js-example-basic-single col-sm-12"
                        isMulti
                        value={formValues.roles}
                        onChange={handleSelectChange}
                    />
                </FormGroup>
                <div className="text-end">
                    <Button color="primary" onClick={onEditSubmit}>{'+ Add New Position'}</Button>
                </div>
            </ModalBody>
        </Modal >
    );
};

export default AddPositionModal;
