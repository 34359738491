import React, { Fragment, useEffect, useState, } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import _ from 'lodash';
import { getTotalAvailableTermsAPI } from '../../../../Library/apis';
import TermSavingsTable from './TermSavingsTable';

const TermSavings = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getTotalAvailableTermsAPI().then(res => {
            setIsLoading(false);
            setData(res.body);
        }).catch(() => {
            setIsLoading(false);
        })
    }, [])

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Reports/Records' />
            <Container fluid={true}>
                <Row className='widget-grid'>
                    <TermSavingsTable data={data} isLoading={isLoading} />
                </Row>
            </Container>
        </Fragment>
    );
};

export default TermSavings;
