import React, { Fragment, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { Btn, H4, Image } from '../../../../AbstractElements';
import logoWhite from '../../../../assets/images/logo/logo.png';
import logoDark from '../../../../assets/images/logo/logo_dark.png';
import { activeUserAPI } from '../../../../Library/apis';
import { toast } from 'react-toastify';

const CodeVerification = ({ logoClassMain }) => {
  const location = useLocation();
  const userInfo = location.state?.userInfo;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const nativate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { userInfo, activationCode: code };
    console.log(data);
    activeUserAPI(data).then(res => {
      toast.success('Successfully Activated!', { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      nativate('/login');
    }).catch(error => {
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message || 'Some thing else may be validation error', { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Fragment>
      <section>
        <Container className='p-0 login-page' fluid={true}>
          <Row className='m-0'>
            <Col className='p-0'>
              <div className='login-card'>
                <div>
                  <div>
                    <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                      <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                      <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
                    </Link>
                  </div>
                  <div className='login-main'>
                    <Form className='theme-form login-form' onSubmit={handleSubmit}>
                      <H4>Acitve Your Account</H4>
                      <FormGroup>
                        <Label className='m-0 col-form-label'>Enter Your Verification Number</Label>
                        <Input className='form-control' type='text' placeholder='00000' value={code} onChange={(e) => setCode(e.target.value)} />
                      </FormGroup>
                      <FormGroup className='text-end'>
                        <Btn attrBtn={{ className: 'btn-block ', color: 'primary', type: 'submit' }}>
                          {loading ? (<><Spinner size="sm" color="light" />{' Loading...'}</>) : ("Verify")}
                        </Btn>
                      </FormGroup>
                      <FormGroup className='mb-4 mt-4'>
                        <span className='reset-password-link'>
                          If don't receive OTC?
                          <a className='btn-link text-danger ml-2' href='#javascript'>Resend</a>
                        </span>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment >
  );
};

export default CodeVerification;
