import React, { Fragment, useContext, useState } from 'react';
import { Btn, H4, P, Image, H1 } from '../../../../AbstractElements';
import { Card, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { adminLoginAPI } from '../../../../Library/apis';
import { toast } from 'react-toastify';
import UserContext from '../../../../_helper/User';
import MothriftLogo from '../../Common/MothriftLogo';

const LoginMain = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setUserInfo } = useContext(UserContext);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        userInfo: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const data = await adminLoginAPI(formData);
            data?.body?.userInfo && setUserInfo(data?.body?.userInfo);
            localStorage.setItem('userInfo', JSON.stringify(data?.body?.userInfo));
            localStorage.setItem('token', data?.body?.token);

            navigate(`/mothrift/home`)
        } catch (error) {
            console.log(error?.response?.data.message)
            toast.error(error?.response?.data?.message || 'Log In error', { autoClose: 1000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <MothriftLogo size={220} white />
            <div style={{ display: 'flex', alignItems: 'center', height: '100vh', background: '#f9f9f9' }}>
                <div style={{ background: '#11131F', width: '35%', height: '100%', marginRight: '130px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <H1 attrH1={{ className: 'text-center font-outfit font60-bold', style: { color: '#7366FF' } }}>{'Mothrift Backend'}</H1>
                </div>
                <Card className='p-4 font-outfit' style={{ width: '400px' }}>
                    <Form className='theme-form login-form' onSubmit={handleSubmit}>
                        <MothriftLogo size={130} style={{position: 'relative', margin: '0 0 10px 0'}}/>
                        <FormGroup >
                            <Label className='col-form-label m-0 text-black'>Email or Phone Number</Label>
                            <Input
                                className='form-control'
                                type='text'
                                name='userInfo'
                                required
                                placeholder='Enter Email or Phone Number'
                                value={formData.userInfo}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup className='position-relative'>
                            <Label className='col-form-label m-0 text-black'>Password</Label>
                            <div className='position-relative'>
                                <Input
                                    className='form-control'
                                    type={togglePassword ? 'text' : 'password'}
                                    name='password'
                                    required
                                    placeholder='*********'
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <div className='show-hide' style={{ top: 20 }} onClick={() => setTogglePassword(!togglePassword)}>
                                    <span className={`text-primary ${togglePassword ? '' : 'show'}`}></span>
                                </div>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Btn attrBtn={{ className: 'd-block w-100 mt-2', color: 'primary', type: 'submit' }}>
                                {loading ? (<><Spinner size="sm" color="light" />{' Loading...'}</>) : ("LOG IN")}
                            </Btn>
                        </FormGroup>

                        <P attrPara={{ className: 'text-center mt-4 mb-4' }}>
                            <Link className='ms-2 text-dark f-w-600' to={`/mothrift/auth/forget-pwd`}>
                                Forgot password?
                            </Link>
                        </P>
                    </Form>
                </Card>
                <H1 attrH1={{ className: 'text-center font-outfit font16-bold', style: { color: '#11131F', position: 'absolute', bottom: 10, right: 30 } }}><i className="fa fa-regular fa-copyright fa-xl me-1" ></i>{`${new Date().getFullYear()} MoThrift. All rights reserved`}</H1>
            </div>
        </Fragment>
    );
};

export default LoginMain;
