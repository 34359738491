import React, { Fragment, } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import _ from 'lodash';

const Customers = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Customers' parent='Mothrift' title='Customers' />
            <Container fluid={true}>
                <Row className='widget-grid'>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Customers;
