import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Card, CardBody, Container, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router';
import DataTable from 'react-data-table-component';
import { TrendingDown, TrendingUp } from 'react-feather';

import { Breadcrumbs } from '../../../../AbstractElements';
import { Badges, H3, H6 } from '../../../../AbstractElements';
import { convertDateTime, formatCurrency } from '../../../../Library/custom_utils';
import MothriftLoader from '../../Common/MothriftLoader';
import { getAllPendingWithdrawalsAPI, cancelWithdrawAPI, withdrawApprovalAPI } from '../../../../Library/apis';

const PendingWithdrawalPage = () => {

    const [userPaymentHistory, setUserPaymentHistory] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        updatePaymentHistory();
    }, [navigate]);

    const updatePaymentHistory = () => {
        setIsLoading(true);
        getAllPendingWithdrawalsAPI().then(res => {
            setIsLoading(false);
            setUserPaymentHistory(res.body);
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const tableColumns = [
        {
            name: 'User ID',
            selector: row => row['user'],
            width: '140px',
            center: false,
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            center: false,
        },
        {
            name: 'Date/Time',
            selector: row => row['startDate'],
            center: true,
        },
        {
            name: 'Bank Name',
            selector: row => row['bankName'],
            center: true,
        },
        {
            name: 'Bank Account',
            selector: row => row['accountNumber'],
            center: true,
        },
        {
            name: 'Wallet Balance',
            selector: row => row['totalAmount'],
            center: true,
        },
        {
            name: 'Status',
            selector: row => row['status'],
            center: true,
            width: '100px'
        },
        {
            name: 'Action',
            selector: row => row['action'],
            center: true,
        },
    ];

    const [isActionLoading, setIsActionLoading] = useState({});

    const renderItem = (item) => {

        const bankName = item?.details?.data?.sourceInformation?.bankName || item?.details?.bankName || item?.bankName || 'Access Bank';
        const accountNumber = item?.details?.data?.sourceInformation?.sourceAccountNumber || item?.details?.beneficiaryAccountNumber || item?.details?.accountNumber || '0000000000';
        const statusColor = item.status == 'Success' ? 'success' : item.status == 'Pending' ? 'info' : 'danger';


        const handleAccept = (id) => {
            setIsActionLoading(prev => ({ ...prev, [id]: true }));
            withdrawApprovalAPI({ id: id }).then((res) => {
                updatePaymentHistory();
            }).catch((err) => {
                updatePaymentHistory();
            }).finally(() => {
                setIsActionLoading(prev => ({ ...prev, [id]: false }));
            });
        };

        const handleDecline = (id) => {
            setIsActionLoading(prev => ({ ...prev, [id]: true }));
            cancelWithdrawAPI({ id: id }).then((res) => {
                updatePaymentHistory();
            }).catch((err) => {
                updatePaymentHistory();
            }).finally(() => {
                setIsActionLoading(prev => ({ ...prev, [id]: false }));
            });
        };


        return {
            user: <H6 attrH6={{ className: 'f-14 f-light' }}>{item.userId}</H6>,
            amount:
                <div className='d-flex'>
                    {item.type == 'Withraw' ? <TrendingDown className='font-danger me-2' /> : <TrendingUp className='font-success me-2' />}
                    <div>
                        <H6 attrH6={{ className: 'f-14 mb-2' }}>{`${item.type == 'Withraw' ? 'Withdraw' : 'Deposit'}`}</H6>
                        <H3 attrH3={{ className: 'f-14 mb-0 f-light' }}>{formatCurrency(item.amount)}</H3>
                    </div>
                </div>,
            startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.createdAt)}</H6>,
            bankName: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{bankName}</H6>,
            accountNumber: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{accountNumber}</H6>,
            status: <Badges attrBadge={{ className: 'badge rounded-pill', color: statusColor, pill: true, style: { minWidth: 60 } }}>{item.status}</Badges>,
            action: <div>
                {item.status == 'Pending' ? (
                    <div className='d-flex gap-1' style={{ width: 180, justifyContent: 'center' }}>
                        {isActionLoading[item._id] ? (
                            <Spinner size='sm' color='success' />
                        ) : (
                            <>
                                <div className='mothrift-button' onClick={() => handleAccept(item._id)}>
                                    <Badges attrBadge={{ className: 'badge rounded-pill p-2', color: 'success', pill: true, style: { minWidth: 70 } }}>Accept</Badges>
                                </div>
                                <div className='mothrift-button' onClick={() => handleDecline(item._id)}>
                                    <Badges attrBadge={{ className: 'badge rounded-pill p-2', color: 'warning', pill: true, style: { minWidth: 70 } }}>Decline</Badges>
                                </div>
                            </>
                        )}
                    </div>
                ) : <></>
                }
            </div>,

            totalAmount: <span className="font-success font18-bold">{formatCurrency(item.totalAmount)}</span>,
        }
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Pending Withdrawal' />
            <Container fluid={true}>
                <Card className='tranaction-card'>
                    <CardBody className='pt-4'>
                        <div className={`table-responsive recent-table transaction-table`}>
                            <Fragment>
                                {isLoading ? <MothriftLoader /> :
                                    <DataTable
                                        data={userPaymentHistory.filter(item => item.type == 'Withraw').map(item => renderItem(item))}
                                        columns={tableColumns}
                                        striped
                                        center
                                        paginationPerPage={20}
                                        pagination
                                    />}
                            </Fragment>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default PendingWithdrawalPage;
