import React, { Fragment, useContext, useEffect } from 'react';
import { useState } from 'react';
import NavCustomizer from './NavCustomizer';
import TabCustomizer from './TabCustomizer';
import CustomizerContext from '../../_helper/Customizer';
import { isLocalServer } from '../../Library/custom_utils';

const Themecustomizer = () => {
  const [selected, setSelected] = useState('check-layout');
  const [openCus, setOpenCus] = useState(false);

  const { addMixBackgroundLayout, setMixLayout } = useContext(CustomizerContext);
  useEffect(() => {
    setMixLayout(false);
    document.body.classList.add('dark-sidebar');
    handleCustomizerMix_Background('');
  }, [setMixLayout]);

  const handleCustomizerMix_Background = (value) => {
    addMixBackgroundLayout('dark-sidebar');
    document.body.classList.remove('light-only');
    document.body.classList.add('dark-sidebar');
    document.body.classList.remove('dark-only');
  };

  const callbackNav = ((select, open) => {
    setSelected(select);
    setOpenCus(open);
  });

  return (
    <Fragment>
      <div className={`customizer-links ${openCus ? 'open' : ''}`}>
        {isLocalServer() && <NavCustomizer callbackNav={callbackNav} selected={selected} />}

      </div>
      <div className={`customizer-contain ${openCus ? 'open' : ''}`}>
        {isLocalServer() && <TabCustomizer selected={selected} callbackNavTab={callbackNav} />}
      </div>
    </Fragment>
  );
};

export default Themecustomizer;
