import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner, Button } from 'reactstrap'
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import UserContext from "../../../../_helper/User";
import { deleteStaffAPI, disableStaffAPI, updateStaffAPI } from "../../../../Library/apis";

const EditStaffsModal = ({ modal, toggle, item, updateStaffList }) => {

    const { staffRoles } = useContext(UserContext);
    const { handleSubmit } = useForm();

    const [formValues, setFormValues] = useState({
        staffId: '',
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        positionId: "",
        profileImageUrl: ""
    });

    useEffect(() => {
        if (item) {
            setFormValues({
                staffId: item._id || '',
                firstName: item?.userInfo.firstName || "",
                middleName: item?.userInfo.middleName || "",
                lastName: item?.userInfo.lastName || "",
                phoneNumber: item.phoneNumber || "",
                profileImageUrl: item.profileImageUrl || "",
                email: item.email || "",
                positionId: item.positionId || "",
            })
        }
    }, [item])

    const handleUpdate = () => {
        updateStaffAPI(formValues).then((res) => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch((error) => {
            const message = error?.response?.data?.message || 'Update Profile Error'
            toast.error(message, { autoClose: 1000 });
        });
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const handleSelectChange = (e) => {
        setFormValues(({ ...formValues, positionId: e.target.value }));
    };

    const handleActive = (e) => {
        disableStaffAPI({ staffId: formValues.staffId }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch(e => toast.error('Staff Disable/Enable error'));
    };

    const handleDelete = (e) => {
        deleteStaffAPI({ staffId: formValues.staffId }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch(e => toast.error('Staff Delete error'));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >{'Edit Staff'}</ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Form>
                    <Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                    disabled={!item?.active}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Middle Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.middleName || ""}
                                    name="middleName"
                                    onChange={handleChange}
                                    placeholder="Middle Name"
                                    disabled={!item?.active}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Sure Name"
                                    disabled={!item?.active}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    disabled={!item?.active}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    disabled={!item?.active}
                                    required />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Staff Position'}</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    className="form-control btn-square"
                                    disabled={!item?.active || item?.superAdmin}
                                    value={formValues.positionId}
                                    onChange={handleSelectChange}
                                >
                                    <option value={''} key={-1}>{'Select'}</option>
                                    {staffRoles.map((items, i) =>
                                        <option value={items._id} key={i}>{items.positionName}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                {!item?.superAdmin && <div
                    className="text-end">
                    <Button color="info" onClick={handleActive} className="me-2">
                        {item?.active ? 'Disable' : 'Enable'}
                    </Button>
                    <Button color="secondary" onClick={handleDelete} className="me-2">
                        {'Delete'}
                    </Button>
                    <Button color="success" onClick={handleUpdate} className="me-2">
                        {'Update'}
                    </Button>
                </div>}
            </div>
        </Modal >
    );
};

export default EditStaffsModal;
