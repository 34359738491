import React from 'react';
import { COLORS } from '../../../Library/theme';
import { PulseLoader } from 'react-spinners';

const MothriftLoader = ({ size = 70, color = COLORS.primary, style = {} }) => {
    return (
        <div className='d-flex w-100 justify-content-center align-items-center' style={{ height: size }}>
            <PulseLoader color={color} size={15} />
        </div>
    )
};

export default MothriftLoader;