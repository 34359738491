import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { convertDate, convertDate2, convertDateTime, formatCurrency } from '../../../../Library/custom_utils';
import { H3, H6 } from '../../../../AbstractElements';
import MothriftLoader from '../../Common/MothriftLoader';

const tableColumns = [
    {
        name: 'User ID',
        selector: row => row['userId'],
        sortable: true,
        center: false,
    },
    {
        name: 'Amount',
        selector: row => row['amount'],
        sortable: true,
        center: false,
    },
    {
        name: 'Phone Number',
        selector: row => row['phoneNumber'],
        sortable: true,
        center: true,
    },
    {
        name: 'Date',
        selector: row => row['date'],
        sortable: true,
        center: true,
    },
    {
        name: 'Bonus',
        selector: row => row['bonus'],
        sortable: true,
        center: true,
    },
    {
        name: 'Total Balance',
        selector: row => row['total_balance'],
        sortable: true,
        center: true,
    }
];

const DataTableComponent = ({ data, isLoading }) => {

    const renderItem = (item) => {

        return {
            userId: <H6 attrH6={{ className: 'f-14 f-light' }}>{item.userId}</H6>,
            amount: <H3 attrH3={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.amount)}</H3>,
            date: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.createdAt)}</H6>,
            phoneNumber: <H6 attrH6={{ className: 'f-14 mb-2 f-light' }}>{item.phoneNumber}</H6>,
            bonus: <H6 attrH6={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.bonus)}</H6>,
            total_balance: <H6 attrH6={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.totalBalance)}</H6>,
        }
    }

    return (
        <Fragment>
            {isLoading ? <MothriftLoader /> :
                <DataTable
                    data={data.map((item, i) => renderItem(item, i))}
                    columns={tableColumns}
                    striped
                    center
                    paginationPerPage={20}
                    pagination
                />}
        </Fragment>
    )
}
export default DataTableComponent