import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import { FaEdit } from 'react-icons/fa';

import { H4 } from '../../../AbstractElements';
import SvgIcon from '../../Common/Component/SvgIcon';
import SetBalanceModal from './SetBalanceModal';

const Widget = ({ data, value, handleClick, isLoading }) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Card className='widget-1 position-relative' style={{ minHeight: 150 }}>
      <div
        className='position-absolute d-flex justify-content-center align-items-center'
        style={{
          width: 30,
          height: 30,
          right: 20,
          fill: 'red',
          top: 15,
          paddingLeft: 2,
          borderRadius: 100,
          background: `${data.mainColor}27`,
          color: data.mainColor,
          cursor: 'pointer'
        }}
        onClick={toggle}
      >
        <FaEdit />
      </div>
      <div className='position-absolute' style={{ left: 0, fill: 'red', top: 'calc(50% - 60px)' }}>
        <SvgIcon className='svg-fill' iconId={`left-border-${data.color}`} />
      </div>
      <div className='p-4'>
        <div className='d-flex align-items-center gap-2'>
          <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', background: `${data.mainColor}27`, borderRadius: '5px' }}>
            <SvgIcon className='svg-fill' iconId={`${data.icon}`} style={{ width: '20px', height: '20px' }} />
          </div>
          <span className='font14'>{data.title}</span>
        </div>
        <div className='mt-3 mb-3' style={{ width: '90%', height: '1px', background: '#3333' }} />
        {!isLoading && <H4 attrH4={{ className: 'text-muted font34 mt-2 mb-2 ms-4' }}>{value}</H4>}
        {isLoading && <div className='ms-4' style={{ height: 55,  display: 'flex', alignItems: 'center'}}><PulseLoader color={data.mainColor} size={10} /></div>}
      </div>
      <SetBalanceModal
        modal={modal}
        toggle={toggle}
        handleClick={handleClick}
        title={`Update ${data.title}`}
        color={data.color}
      />
    </Card >
  );
};

export default Widget;
