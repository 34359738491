import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner, Button, ModalBody } from 'reactstrap'
import { toast } from "react-toastify";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import UserContext from "../../../../_helper/User";
import { updateStaffRoleAPI } from "../../../../Library/apis";

const animatedComponents = makeAnimated();

const EditPositionModal = ({ modal, toggle, selectedRole, roles }) => {

    const { updateStaffRoles } = useContext(UserContext)

    const [formValues, setFormValues] = useState({
        positionId: '',
        positionName: "",
        roles: []
    });

    useEffect(() => {
        if (selectedRole) {
            const positionName = selectedRole.positionName;
            const updatedRoles = selectedRole.roles.map((role) => {
                const roleObject = roles.find(r => r.label == role);
                const index = roleObject ? roleObject.value : null;
                return { value: index, label: role }
            })
            setFormValues({ positionId: selectedRole._id, positionName, roles: updatedRoles });
        }
    }, [selectedRole, roles])

    const onEditSubmit = (e) => {
        updateStaffRoleAPI(
            {
                positionId: formValues.positionId,
                positionName: formValues.positionName,
                roles: formValues.roles.map(role => role.label),
            }).then(res => {
                toast.success("Successfully Updated!", { autoClose: 1000 });
                updateStaffRoles();
                toggle();
            }).catch(e => {
                toast.error("Update failed!", { autoClose: 1000 });
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSelectChange = (selectedOptions) => {
        setFormValues(prevValues => ({ ...prevValues, roles: selectedOptions }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >{'Edit Staff Position'}</ModalHeader>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3">
                        <Label className="form-label">{'Positon Name'}</Label>
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Position Name *"
                            name="positionName"
                            required
                            value={formValues?.positionName || ""}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-label">{'Staff Position'}</Label>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={roles}
                            className="js-example-basic-single col-sm-12"
                            isMulti
                            value={formValues?.roles || []}
                            onChange={handleSelectChange}
                        />
                    </FormGroup>
                </Row>
                <div className="text-end">
                    <Button color="primary" onClick={onEditSubmit}>{'Update'}</Button>
                </div>
            </ModalBody >
        </Modal>
    );
};

export default EditPositionModal;
