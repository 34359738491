import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { formatCurrency, formatPercentage } from '../../../Library/custom_utils';
import Widgets1 from './Widgets1';
import { H3 } from '../../../AbstractElements';

const BalanceInfoWidget = ({ balanceInfo, isLoading }) => {

  const data = [
    {
      title: 'Interest rate',
      color: 'info',
      mainColor: '#59CFEB',
      icon: 'top-interests-rate',
      value: formatPercentage(balanceInfo.rate)
    },
    {
      title: 'Refferal Bonus',
      color: 'secondary',
      mainColor: '#FF5F55',
      icon: 'top-bonus',
      value: formatCurrency(balanceInfo.refferalBonus)
    },
    {
      title: 'Referral Threshold Amount',
      color: 'secondary',
      mainColor: '#FF37B1',
      icon: 'top-customers',
      value: formatCurrency(balanceInfo.min_refferal_bonus_fix_amount)
    },
    {
      title: 'Term Savings Threshold Amount',
      color: 'primary',
      mainColor: '#5167F1',
      icon: 'top-savings',
      value: formatCurrency(balanceInfo.term_savings_threshold)
    },
    {
      title: 'Max Term Savings Count',
      color: 'warning',
      mainColor: '#F3C568',
      icon: 'top-staff',
      value: `${balanceInfo.max_activity_count}`
    },
    {
      title: 'Airtime Bonus Amount',
      color: 'secondary',
      mainColor: '#FF5F55',
      icon: 'top-bonus',
      value: formatCurrency(balanceInfo.airtime_bonus_amount)
    },
    {
      title: 'Max Airtime Bonus Count',
      color: 'secondary',
      mainColor: '#FF5F55',
      icon: 'top-bonus',
      value: `${balanceInfo.airtime_max_count}`
    },
  ]

  return (
    <Container>
      <H3 attrH3={{ className: 'mb-3 f-light' }}>{'Financial Parameters'}</H3>
      <Row>
        {data.map((item, i) => <Col key={`balanceInfo_${i}`} xxl='3' xl={4} lg={6} sm={12}>
          <Widgets1 data={item} isLoading={isLoading} />
        </Col>)}
      </Row>
    </Container>
  );
};

export default BalanceInfoWidget;
