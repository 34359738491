export function formatCurrency(value) {
  let num = Number(value);
  if (isNaN(num)) num = 0;
  const roundedNum = Math.floor(num * 100) / 100;
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(roundedNum);
}

export function formatPercentage(value) {
  let num = Number(value);
  if (isNaN(num)) num = 0;
  const roundedNum = Math.floor(num * 100);
  return `${value}(${roundedNum}%)`;
}

export function convertDate(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based

  // Format the date string as "YYYY-DD-MM"
  return `${year}-${month}-${day}`;
}

export const convertDate2 = (createdAt) => {
  const date = new Date(createdAt);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export function convertDateTime(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date and time string as "MM/DD/YYYY hh:mm"
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export function isLocalServer() {
  return window.location.hostname == 'localhost'
}

export const getTimeDiff = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays}d ago`;
  } else {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Example: "3 Sep 2022"
  }
};

export const getRemainingPercentage = (term, date, status, updatedAt) => {
  try {
    if (!date || !term) {
      throw new Error('Date and term are required parameters');
    }
    if (status == 'Finished') return 100;

    let currentDate = new Date();

    if (status == 'Canceled') currentDate = new Date(updatedAt);
    const startDate = new Date(date);

    if (isNaN(startDate)) {
      throw new Error('Invalid date format');
    }

    if (isNaN(term) || term <= 0) {
      throw new Error('Term must be a positive number');
    }

    const diff = (currentDate - startDate) / (1000 * 3600 * 24);
    const percentage = (diff * 100) / term;
    return parseFloat(percentage.toFixed(2));

  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getTermSavingsEndDate = (item) => {

  if (item.status != 'Started') return convertDate(item.updatedAt);
  const startedDate = new Date(item.createdAt);
  startedDate.setDate(startedDate.getDate() + parseInt(item.term));
  return convertDate(startedDate);
}

function convertArrayToCSV(array, excludeFields = []) {
  // Function to check if the full path of a key is excluded
  const isExcluded = (fullPath) => excludeFields.some(excludedField => fullPath.includes(excludedField));

  // Function to flatten an object
  const flattenObject = (obj, prefix = '') =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;
      if (!isExcluded(fullPath)) {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          Object.assign(acc, flattenObject(value, fullPath));
        } else {
          acc[fullPath] = value;
        }
      }
      return acc;
    }, {});

  // Flatten each object and get all unique keys
  const allKeys = array.reduce((keys, obj) => {
    const flattened = flattenObject(obj);
    Object.keys(flattened).forEach(key => {
      if (!keys.includes(key) && !isExcluded(key)) {
        keys.push(key);
      }
    });
    return keys;
  }, []);

  // Sort keys to ensure consistent order
  allKeys.sort();

  // Map the array of objects to a string, ensuring each object has all keys
  const rows = array.map(obj => {
    const flattened = flattenObject(obj);
    // Map each key to its value, using '--' for missing keys
    const values = allKeys.map(key => {
      if (key in flattened) {
        const value = flattened[key];
        return value !== null && value !== undefined ? `"${value.toString().replace(/"/g, '""')}"` : '""';
      }
      return '"--"'; // Placeholder for missing values
    });
    return values.join(',');
  });

  // Create the header row from the sorted keys
  const headerRow = allKeys.join(',');

  // Combine the header row and the data rows
  return [headerRow, ...rows].join('\n');
}

export function downloadCSV(filename = 'report', array) {
  const excludeFields = ['_id', '__v', 'phoneNumber_fuzzy', 'updatedAt', 'password', 'fullName_fuzzy', 'socketId']
  const csvString = convertArrayToCSV(array, excludeFields);
  const blob = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.download = `${filename}_${convertDate(new Date())}.csv`;
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

