import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EarnHistoryTable from './EarnHistoryTable';
import { getTotalEarnHistoryAPI } from '../../../../Library/apis';
import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import { useNavigate } from 'react-router';
import SubHeader from '../../Common/SubHeader';

const EarnInterest = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getTotalEarnHistoryAPI().then(res => {
            setIsLoading(false);
            setData(res.body);
        }).catch(() => {
            setIsLoading(false);
        })
    }, [navigate])

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Reports/Records' />
            <Container fluid={true}>
                <SubHeader title="Interests" data={data} />
                <Card>
                    <CardBody>
                        <EarnHistoryTable data={data} isLoading={isLoading} />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default EarnInterest;
