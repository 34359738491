import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import Transactions from './Transactions';
import { getAllPaymentHistoryAPI } from '../../../../Library/apis';
import { useNavigate } from 'react-router';


const Savings = () => {

    const [userPaymentHistory, setUserPaymentHistory] = useState([]);
    const naviate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getAllPaymentHistoryAPI().then(res => {
            setIsLoading(false);
            setUserPaymentHistory(res.body);
        }).catch(() => {
            setIsLoading(false);
        })
    }, [naviate]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Reports/Records' />
            <Container fluid={true}>
                <Transactions data={userPaymentHistory} isLoading={isLoading} />
            </Container>
        </Fragment>
    );
};

export default Savings;
