import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Widgets1 from './Widgets1';
import { H3 } from '../../../AbstractElements';
import { formatCurrency } from '../../../Library/custom_utils';

const TotalValueWidget = ({ data, isLoading }) => {

  const DATA = [
    {
      title: 'Total Savings',
      color: 'primary',
      mainColor: '#5167F1',
      icon: 'top-savings',
      value: formatCurrency(data.totalSavingsWallet)
    },
    {
      title: 'Total Term Savings',
      color: 'danger',
      mainColor: '#FF5F55',
      icon: 'top-termsavings',
      value: formatCurrency(data.totalTermSavingsWallet)
    },
    {
      title: 'Total Interests',
      color: 'success',
      mainColor: '#22FF0E',
      icon: 'top-interests',
      value: formatCurrency(data.totalInterestWallet)
    },
    {
      title: 'Total Referrals Paid',
      color: 'secondary2',
      mainColor: '#D76AE5',
      icon: 'top-refferal',
      value: formatCurrency(data.totalReferralPaid)
    },
  ]

  return (
    <Container>
      <H3 attrH3={{ className: 'mb-3 f-light' }}>{'Total Balances'}</H3>
      <Row>
        {DATA.map((item, i) => <Col key={`walletInfo_${i}`} xxl='3' xl={4} lg={6} sm={12}>
          <Widgets1 data={item} isLoading={isLoading} />
        </Col>)}
      </Row>
    </Container>
  );
};

export default TotalValueWidget;
