import React, { useEffect, useState } from 'react';
import Context from './index';

const EmailProvider = (props) => {
    const [faq, setFaq] = useState([]);

    return (
        <Context.Provider
            value={{
                ...props,
                faq
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default EmailProvider;
